/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { useLocation, useHistory, Redirect } from "react-router";
import { formatTime } from "../utils/formatTime";
import { getKeyByValue } from "../utils/getKeyByValue";
import { toString as toLotStatusString } from "../constants/LotStatuses";

const pickOrderLotStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  ISSUE: 2,
});

const pickOrderStatuses = Object.freeze({
  PENDING: 0,
  COMPLETE: 1,
  CANCELLED: 2,
  ISSUE: 3,
  ISSUE_COMPLETE: 4,
  CLOSED: 5,
  PROCESSING: 6,
  CUSTOMER_NOT_FOUND: 7,
  READY: 8,
  PICKED: 9,
});

const StockCheck = (props) => {
  const [stockId, setStockId] = useState("");
  const [stockIdSearched, setStockIdSearched] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const { data, isLoading, mutate } = useMutation(
    ({ stockId }) =>
      axios
        .get(`${process.env.REACT_APP_AOMS_API_URL}/stocks/${stockId}/check`)
        .then((res) => {
          setStockIdSearched(false);
          return res.data;
        }),
    {
      onError: (err) => {
        console.error("Stock check error:", err);
        if (err.response.status === 404) {
          setErrorMessage(`Stock not found ID: ${id}`);
        } else if (err.response.status === 400) {
          setErrorMessage(`Bad stock ID: ${id}`);
        } else {
          setErrorMessage(`Error checking stock ID: ${id}`);
        }
      },
      keepPreviousData: true,
    }
  );

  const stockHistoryMutation = useMutation(({ stockId }) =>
    axios
      .get(
        `${process.env.REACT_APP_AOMS_API_URL}/containers/stock-history/${stockId}`
      )
      .then((res) => res.data)
  );

  useEffect(() => {
    if (id) {
      mutate({ stockId: id });
      stockHistoryMutation.mutate({ stockId: id });
    }
  }, [id]);

  const handleSearch = (e) => {
    if (e.key === "Enter" && stockId) {
      setStockIdSearched(true);
    }
  };

  const millisToDays = (millis) => {
    return Math.round(millis / 1000 / 60 / 60 / 24);
  };

  const handleNavigate = (pickOrderId) => {
    history.push(`/stock-check/${pickOrderId}/pick-order`);
  };

  return (
    <Grid container spacing={6}>
      {stockIdSearched && <Redirect to={`/stock-check?id=${stockId}`} />}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          label="Search Stock"
          type="text"
          name="stockId"
          value={stockId}
          variant="outlined"
          onChange={(e) => setStockId(e.target.value)}
          onKeyPress={handleSearch}
          component={Paper}
          fullWidth
          autoFocus
        />
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : errorMessage ? (
        <Grid item xs={12}>
          <Alert severity="warning">{errorMessage}</Alert>
        </Grid>
      ) : (
        data && (
          <>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Stock Details
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Stock ID"
                      value={data.stockCheckInfo.stockId || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Container"
                      value={data.stockCheckInfo.containerName || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                      label="Stock Last Modified On"
                      value={
                        data.stockCheckInfo.stockLastModifiedDate
                          ? formatTime(
                              data.stockCheckInfo.stockLastModifiedDate
                            )
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                      label="Stock Last Modified By"
                      value={
                        data.stockCheckInfo
                          .stockLastModificationUserFirstName &&
                        data.stockCheckInfo.stockLastModificationUserLastName
                          ? `${data.stockCheckInfo.stockLastModificationUserFirstName} ${data.stockCheckInfo.stockLastModificationUserLastName}`
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                      label={
                        data.stockCheckInfo.postal ? "Dispatched" : "Collected"
                      }
                      value={data.stockCheckInfo.status === 1 ? "Yes" : "No"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {data.imageId && (
              <Grid item xs={12} sm={10} md={6} lg={4}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_CDN_URL}/thumbnails/${data.imageId}`}
                  style={{ width: "100%" }}
                  alt="Stock"
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={8}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Lot Details
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label="Lot Number"
                      value={data.stockCheckInfo.lotNo || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label="Title"
                      value={data.stockCheckInfo.productTitle || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label="Status"
                      value={
                        data
                          ? toLotStatusString(data.stockCheckInfo.lotStatus)
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label="Estate No"
                      value={data.stockCheckInfo.estateNo || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      label="Vendor"
                      value={data.stockCheckInfo.vendorName || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Lot Pick Details
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4} lg={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            data.stockCheckInfo.pickOrderLotStatus ===
                            pickOrderLotStatuses.COMPLETE
                          }
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      }
                      label="Picked"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Pick Order Lot Status"
                      value={
                        data.stockCheckInfo.pickOrderLotStatus
                          ? getKeyByValue(
                              pickOrderLotStatuses,
                              data.stockCheckInfo.pickOrderLotStatus
                            )
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Pick Issue"
                      value={data.stockCheckInfo.pickIssue || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Sortation Container"
                      value={data.stockCheckInfo.sortationContainerName || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Pick Batch User"
                      value={
                        data.stockCheckInfo.pickBatchUserFirstName &&
                        data.stockCheckInfo.pickBatchUserLastName
                          ? `${data.stockCheckInfo.pickBatchUserFirstName} ${data.stockCheckInfo.pickBatchUserLastName}`
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Pick Order Status"
                      value={
                        data.stockCheckInfo.pickOrderStatus
                          ? getKeyByValue(
                              pickOrderStatuses,
                              data.stockCheckInfo.pickOrderStatus
                            )
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Pick Order ID"
                      value={data.stockCheckInfo.pickOrderId || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={2} component={Paper}>
                <Typography variant="h6" gutterBottom>
                  Invoice Details
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Invoice ID"
                      value={data.stockCheckInfo.invoiceId || "-"}
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Type"
                      value={
                        data.stockCheckInfo.postal ? "Postal" : "Collection"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {!data.stockCheckInfo.postal && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        label="Invoice Collected"
                        value={
                          data.stockCheckInfo.invoiceCollected
                            ? "Collected"
                            : "Not Collected"
                        }
                        inputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Invoice Completion Date"
                      value={
                        data.stockCheckInfo.invoiceCompletionDate
                          ? formatTime(
                              data.stockCheckInfo.invoiceCompletionDate
                            )
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      label="Age (days)"
                      value={
                        data.stockCheckInfo.invoiceCompletionDate
                          ? millisToDays(
                              new Date() -
                                new Date(
                                  data.stockCheckInfo.invoiceCompletionDate
                                )
                            )
                          : "-"
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {data.linkedPickOrders && (
              <Grid item xs={12}>
                <Box p={2} component={Paper}>
                  <Typography variant="h6" gutterBottom>
                    Linked Pick Orders
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Id</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ transform: "rotate(0)" }}>
                        {data.linkedPickOrders.map((linkedPickOrder) => {
                          return (
                            <TableRow
                              onClick={() => handleNavigate(linkedPickOrder.id)}
                              hover
                              key={linkedPickOrder.id}
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  linkedPickOrder.id ===
                                  data.stockCheckInfo.pickOrderId
                                    ? "lightYellow"
                                    : "",
                              }}
                            >
                              <TableCell>{linkedPickOrder.id || "-"}</TableCell>
                              <TableCell>
                                {linkedPickOrder.pickOrderStatus
                                  ? getKeyByValue(
                                      pickOrderStatuses,
                                      linkedPickOrder.pickOrderStatus
                                    )
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            )}
            {stockHistoryMutation.data && (
              <Grid item xs={12}>
                <Box p={2} component={Paper}>
                  <Typography variant="h6" gutterBottom>
                    Stock History
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell>User</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stockHistoryMutation.data.result.map((el) => (
                          <TableRow
                            key={el.id}
                            hover
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{formatTime(el.creationDate)}</TableCell>
                            <TableCell>{el.location}</TableCell>
                            <TableCell>{el.user}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            )}
          </>
        )
      )}
    </Grid>
  );
};

export default StockCheck;
