const LotStatuses = Object.freeze({
  AWAITING_SALE: 0,
  SOLD: 1,
  NOT_SOLD: 2,
  PENDING_SECOND_CHANCE: 3,
});

const toString = (status) => {
  switch (status) {
    case LotStatuses.AWAITING_SALE:
      return "Awaiting Sale";
    case LotStatuses.SOLD:
      return "Sold";
    case LotStatuses.NOT_SOLD:
      return "Not Sold";
    case LotStatuses.PENDING_SECOND_CHANCE:
      return "Pending Second Chance";
    default:
      return "Unknown";
  }
};

export default LotStatuses;

export { toString };
